<script lang="ts">
  import {
    computed,
    defineComponent,
    PropType,
    ref,
    toRefs,
    unref,
    watch,
  } from "vue";
  import flatpickr from "flatpickr";
  import { Instance } from "flatpickr/dist/types/instance.d";
  import { Options } from "flatpickr/dist/types/options.d";
  import { German } from "flatpickr/dist/l10n/de.js";
  import { isSameDay, isSameSecond } from "date-fns";

  import {
    DateFormat,
    formatDateWithString,
    mergeDates,
    shiftFromLocalToUTC,
    shiftFromUTCToLocal,
  } from "shared/utils/date-utils";
  import { Errors } from "frontend/uses/use-errors";
  import { StatusValue } from "frontend/utils/animation-status";
  import { useFormLabelling } from "frontend/uses/use-form-labelling";

  import AnimationStatus from "frontend/components/AnimationStatus.vue";

  const V_MODEL_EVENT = "update:modelValue";

  export default defineComponent({
    components: { AnimationStatus },
    props: {
      label: {
        type: String,
        default: null,
      },
      modelValue: {
        type: Date as PropType<Date | null>,
        default: null,
      },
      enableTime: {
        type: Boolean,
        default: false,
      },
      baseDate: {
        // using this fixes the date to this and lets only select time
        type: Date,
        default: null,
      },
      useUtc: {
        // using this simulates (!) UTC-Times (and dates)
        type: Boolean,
        default: false,
      },
      allowDeletion: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      errors: {
        type: Array as PropType<Errors>,
        default: () => [],
      },
      animationStatus: {
        type: Number as PropType<StatusValue | null>,
        default: null,
      },
      minDate: {
        type: Date,
        default: new Date("1900-01-01"),
      },
      maxDate: {
        type: Date,
        default: null,
      },
      allowDateInput: {
        type: Boolean,
        default: false,
      },
      visualSmall: {
        type: Boolean,
        default: false,
      },
      staticTimePicker: {
        type: Boolean,
        default: false,
      },
    },
    emits: [V_MODEL_EVENT, "autosave"],
    setup(props, { emit }) {
      const {
        modelValue,
        enableTime,
        baseDate,
        useUtc,
        allowDeletion,
        disabled,
        minDate,
        maxDate,
        allowDateInput,
        staticTimePicker,
      } = toRefs(props);
      const container = ref<HTMLElement | null>(null); // flatpickr container
      // simulated utc date if enabled
      const timeZonedDate = computed<Date | null>(() => {
        if (!modelValue.value) return modelValue.value;
        if (useUtc.value) return shiftFromUTCToLocal(modelValue.value);
        else return modelValue.value;
      });
      // do we need a time picker?
      // either the user enabled it specifically or we have a baseDate
      // with a baseDate a picker without time makes no sense
      const timeIsEnabled = computed<boolean>(() => {
        return unref(enableTime) || !!unref(baseDate);
      });
      const noCalendar = computed<boolean>(() => {
        return !!unref(baseDate);
      });
      const isMobilePicker = ref<boolean>(false);
      // the handle for our flatpickr instance
      let beforeValue: Date | null = null;
      let flatpickrHandle: Instance | null = null;
      // eslint-disable-next-line sonarjs/cognitive-complexity -- TODO: find better solution
      watch([container, disabled, minDate, maxDate], () => {
        beforeValue = null;
        if (flatpickrHandle) {
          flatpickrHandle.destroy();
          flatpickrHandle = null;
        }
        if (container.value && !disabled.value) {
          const options: Options = {
            disableMobile: true,
            enableTime: timeIsEnabled.value,
            noCalendar: noCalendar.value,
            time_24hr: true,
            locale: German,
            static: staticTimePicker.value, //enable time input when Picker is in modal
            onOpen: () => {
              beforeValue = modelValue.value;
            },
            onClose: async (selectedDates) => {
              let newValue: Date | null = null;
              if (selectedDates.length === 1)
                newValue = useUtc.value
                  ? shiftFromLocalToUTC(selectedDates[0])
                  : selectedDates[0];
              emit(V_MODEL_EVENT, newValue);
              if (
                !!beforeValue != !!newValue ||
                (beforeValue &&
                  newValue &&
                  !isSameSecond(beforeValue, newValue))
              )
                emit("autosave");
            },
          };
          if (baseDate.value) {
            options.defaultDate = baseDate.value;
          }
          if (timeZonedDate.value) {
            options.defaultDate = timeZonedDate.value;
          }
          if (minDate.value) options.minDate = minDate.value;
          if (maxDate.value) options.maxDate = maxDate.value;

          if (allowDateInput.value) {
            options.allowInput = true;
            options.dateFormat = "d.m.Y";
          }

          options.noCalendar =
            !!baseDate.value ||
            (minDate.value &&
              maxDate.value &&
              isSameDay(minDate.value, maxDate.value));

          flatpickrHandle = flatpickr(container.value, options);
          isMobilePicker.value = !!flatpickrHandle.mobileInput;
        }
      });
      watch(
        [modelValue, timeZonedDate, baseDate],
        (
          [modelValueNew, timeZonedDateNew, baseDateNew],
          [_modelValueOld, _timeZonedDateOld, baseDateOld]
        ) => {
          if (
            modelValueNew &&
            baseDateNew &&
            !isSameDay(modelValueNew, baseDateNew)
          ) {
            emit(
              V_MODEL_EVENT,
              mergeDates(baseDateNew, modelValueNew, baseDateOld)
            );
            return;
          }
          if (flatpickrHandle) {
            if (timeZonedDateNew) {
              // update flatpickr if we receive a new date from the outside
              flatpickrHandle.setDate(timeZonedDateNew, false);
            } else {
              flatpickrHandle.clear();
            }
          }
        }
      );
      // how is the date displayed inside the component?
      const displayDate = computed<string | null>(() => {
        if (!timeZonedDate.value) return null;
        let formatStr = "";
        if (!baseDate.value) formatStr = DateFormat.DateOnly;
        if (timeIsEnabled.value)
          formatStr += " " + DateFormat.TimeOnlyWithoutSeconds;
        return formatDateWithString(timeZonedDate.value, formatStr);
      });
      // clearing the date from the component
      const doClearDate = (event: Event) => {
        if (!allowDeletion.value) return;
        if (disabled.value) return;
        event.stopPropagation();
        if (flatpickrHandle) flatpickrHandle.clear();
        emit(V_MODEL_EVENT, null);
        emit("autosave");
      };

      const { pairing } = useFormLabelling();
      return {
        container,
        displayDate,
        doClearDate,

        isMobilePicker,

        pairing,
      };
    },
  });
</script>

<template>
  <div class="vue__datetime-picker">
    <label v-if="label" class="form-datetime__label" v-bind:for="pairing">{{
      label
    }}</label>
    <div class="form-datetime__status-container">
      <template v-if="allowDateInput">
        <input
          ref="container"
          class="datetime-picker__container"
          v-bind:class="{
            'datetime-picker__container--disabled': disabled,
            'datetime-picker__container--mobile': isMobilePicker && !disabled,
            'datetime-picker__container--small': visualSmall,
          }"
          v-bind:value="displayDate"
        />
      </template>
      <template v-else>
        <div
          ref="container"
          class="datetime-picker__container"
          v-bind:class="{
            'datetime-picker__container--disabled': disabled,
            'datetime-picker__container--mobile': isMobilePicker && !disabled,
            'datetime-picker__container--small': visualSmall,
          }"
        >
          {{ displayDate }}
          <span
            v-if="displayDate && allowDeletion"
            class="datetime-picker__clear-button"
            v-on:click="doClearDate"
            >Eingabe löschen</span
          >&nbsp;
        </div>
      </template>

      <AnimationStatus
        v-if="animationStatus !== null"
        class="form-datetime__animation-status"
        v-bind:animation-status="animationStatus"
      />
    </div>
    <template v-if="errors && errors.length > 0">
      <label
        v-for="error in errors"
        v-bind:key="error"
        class="datetime-picker__label__error"
        v-bind:for="pairing"
      >
        {{ error }}
      </label>
    </template>
  </div>
</template>

<style src="flatpickr/dist/flatpickr.css"></style>
<style lang="scss" scoped>
  @use "frontend/styles/colors";
  @use "frontend/styles/mixins/label";

  .vue__datetime-picker {
    padding: 5px 0;

    .datetime-picker__clear-button {
      cursor: pointer;
      font-style: italic;
      font-size: 12px;
      margin-left: 10px;
    }

    .datetime-picker__container {
      border: 1px solid colors.$color_input--border;
      padding: 7px;
    }
    .datetime-picker__container--small {
      padding: 3px;
    }

    .datetime-picker__container--disabled {
      cursor: not-allowed;
    }
  }

  .form-datetime__label {
    @include label.label;
  }

  .datetime-picker__label__error {
    @include label.label;
    @include label.label--error;
  }
  .form-datetime__status-container {
    display: flex;

    align-items: center;

    .datetime-picker__container {
      flex: 1;
      width: 100%;
    }
  }

  .form-datetime__animation-status {
    margin-left: 10px;
    margin-top: 4px;
  }

  .datetime-picker__container--mobile {
    display: none;
  }

  :deep(.flatpickr-wrapper) {
    width: 100%;
  }
</style>
