import { v4 as uuid } from "uuid";

import { HttpPayload } from "frontend/interfaces/http";
import {
  Reminder,
  ReminderNew,
  ReminderRaw,
} from "frontend/interfaces/settings/reminder";

export function parseReminder(reminder: ReminderRaw): Reminder {
  return {
    ...reminder,
  };
}

// eslint-disable-next-line sonarjs/no-identical-functions -- the will not stay identical forever
export function httpPayloadReminder(reminder: Reminder): HttpPayload {
  return {
    ...reminder,
  };
}

export function newReminder(): ReminderNew {
  return {
    id: `MOCK-${uuid()}`,
    time_number: 1,
    time_unit: "hour",
    unsaved: true,
  };
}
