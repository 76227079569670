import { log, LogLevel } from "shared/utils/logger";
import {
  OfficeStoreHeaders,
  PatientStoreHeaders,
  RESPONSE_HEADER_OFFICE_STORE,
  RESPONSE_HEADER_PATIENT_STORE,
} from "frontend/api/application";
import {
  OnRequestHasRawResponse,
  OnRequestHasOfficeStoreHeaders,
  OnRequestHasPatientStoreHeaders,
} from "frontend/events/topics";

export function setupEvents(): void {
  translateStoreHeaders();
}

function translateStoreHeaders(): void {
  // TODO: refactor to remove redundant code
  OnRequestHasRawResponse.subscribe(({ path, rawResponse }) => {
    // translate incoming request to office headers
    // if there are valid office headers
    if (
      rawResponse.headers &&
      rawResponse.headers[RESPONSE_HEADER_OFFICE_STORE]
    ) {
      try {
        const headerContent = JSON.parse(
          rawResponse.headers[RESPONSE_HEADER_OFFICE_STORE]
        );
        log(LogLevel.Debug, "[EVENT-TRANSLATION] got office store headers");
        OnRequestHasOfficeStoreHeaders.emit({
          path,
          headers: headerContent as OfficeStoreHeaders,
        });
      } catch (e) {
        log(
          LogLevel.Error,
          `Could not parse ${RESPONSE_HEADER_OFFICE_STORE} header. Expected json.`
        );
      }
    }
    // translate incoming request to patient headers
    // if there are valid office headers
    if (
      rawResponse.headers &&
      rawResponse.headers[RESPONSE_HEADER_PATIENT_STORE]
    ) {
      try {
        const patientHeaderContent = JSON.parse(
          rawResponse.headers["patient-store"]
        );
        log(LogLevel.Debug, "[EVENT-TRANSLATION] got patient store headers");
        OnRequestHasPatientStoreHeaders.emit({
          path,
          headers: patientHeaderContent as PatientStoreHeaders,
        });
      } catch (e) {
        log(
          LogLevel.Error,
          `Could not parse ${RESPONSE_HEADER_PATIENT_STORE} header. Expected json.`
        );
      }
    }
  });
}
