import { Component } from "vue";
import { StoreDefinition } from "pinia";

import { log, LogLevel } from "shared/utils/logger";

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type AllProps<T extends Component> = T extends Component<infer X> ? X : never;
export type PartialProps<T extends Component> = Partial<AllProps<T>>;
export type ExtractPropType<
  T extends Component,
  PropName extends string,
> = AllProps<T>[PropName];

export type ExtractStateFromStoreDefinition<UseStore> =
  UseStore extends StoreDefinition<string, infer Y> ? Y : never;

export function assertUnreachable(variable: never): never {
  // this should never happen as typescript should catch the error
  // compile time --> code that includes this should never compile without
  // an error
  log(
    LogLevel.Error,
    "This should never happen. Code should never reach this point!",
    variable as unknown
  );
  throw new Error(
    "This should never happen. Code should never reach this point!"
  );
}

// from: https://stackoverflow.com/a/51399781
export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export function assertEntryType<EntryType, T>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- assertion function for typescript
  objectToAssert: T,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- assertion function for typescript
  errorMessage: T[keyof T] extends EntryType ? string : never
): void {
  return;
}

export type AssertsNonUnion<T> = [T] extends [infer U]
  ? U extends unknown // Make T non-distributive, and (U, an alias for T) distributive
    ? T extends U
      ? T
      : never
    : never
  : never;

export type NoUndefined<T> = T extends undefined ? never : T;

export type RequiredKeys<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>;

// if using this method, you should add a typecheck
// to ensure the types match exactly
// Example (taken from ShowAbsence.vue):
//  type OmmitedKeys = "persons" | "person_ids";
//  const ommitedKeys = ["person_ids", "persons"] as const;
//  type assertionForOmmitedKeys = AssertTrue<
//    IsExact<typeof ommitedKeys[number], OmmitedKeys>
//  >;
//  omit<SomeInterface, OmmitedKeys>(someInterfaceInstance, ommitedKeys);
export function omit<T, K extends keyof T>(
  object: T,
  keys: Readonly<Array<K>>
): Omit<T, Extract<keyof T, K>> {
  const copy = { ...object };
  for (const key of keys) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- legacy implementation, maybe fix upon touching
    delete copy[key];
  }
  return copy;
}

// inspired by https://stackoverflow.com/a/49579497
type RequiredKeysOf<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types -- need as placeholder
  [K in keyof T]-?: {} extends { [P in K]: T[K] } ? never : K;
}[keyof T];
export type ExcludeOptionalProps<T> = Pick<T, RequiredKeysOf<T>>;
