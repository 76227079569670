import { routes, doRequest } from "frontend/api/application";
import { HttpMethod, HttpPayload } from "frontend/interfaces/http";

export interface LoginStatusResponse {
  current_user: {
    present: boolean;
    office: boolean;
    patient: boolean;
  };
}

export async function requestLoginStatus(): Promise<LoginStatusResponse> {
  const { body } = await doRequest(
    HttpMethod.GET,
    routes.paths.login_status_frontend_misc_path,
    {},
    {}
  );

  return parseRawLoginStatusResponse(body);
}

export function parseRawLoginStatusResponse(
  raw: HttpPayload | null
): LoginStatusResponse {
  const current_user =
    typeof raw?.current_user === "object" && !Array.isArray(raw?.current_user)
      ? raw.current_user ?? {}
      : {};

  return {
    current_user: {
      present: !!current_user.present,
      office: !!current_user.office,
      patient: !!current_user.patient,
    },
  };
}
