import { ComponentProps, useModal } from "vue-final-modal";
import { Component, isReactive, PropType, reactive, toRefs } from "vue";

import { ModalOptions } from "frontend/uses/simple-modal/variants/simple-modal";
import { AlertModal } from "frontend/uses/simple-modal/variants/alert-modal";
import { ConfirmModal } from "frontend/uses/simple-modal/variants/confirm-modal";
import { FailureModal } from "frontend/uses/simple-modal/variants/failure-modal";
import { log, LogLevel } from "shared/utils/logger";
import { eventNameConverter } from "frontend/utils/event-name-converter";

import BaseModal from "frontend/components/base/BaseModal.vue";
import SimpleModal from "frontend/uses/simple-modal/SimpleModal.vue";

interface ModalResult {
  confirm: boolean;
}

type ModalCreator = (
  content: string,
  options: ModalOptions
) => Promise<ModalResult>;

// eslint-disable-next-line @typescript-eslint/ban-types -- given by vue-final-modal
export type ModalComponentOn = Record<string, Function | Function[]>;
export type ModalProps = Record<string, unknown>;

interface ModalCustomOptions {
  hideCancel: boolean;
  hideConfirm: boolean;
  noAutoClose?: boolean; // if this is set the modal will only close if the second argument of onClose is called manually!
  onClose?: (confirm: boolean, close: () => void) => void;
  componentProps?: ModalProps;
  componentOn?: ModalComponentOn;
  confirmLabel?: string;
}

export interface ModalChild {
  doClose: (confirm: boolean) => Promise<void>;
}

interface CustomModalProps extends ComponentProps<typeof SimpleModal> {
  hideCancel?: boolean;
  hideConfirm?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
}

export function useAsModalChildProps() {
  return {
    asModalChild: {
      type: Object as PropType<ModalChild | null>,
      default: null,
    } as const,
  } as const;
}

// Creation of simple, pre-defined modals as well as custom modals that show any component as
// modal
// See also app/packs/frontend/utils/modals for a more elaborate way to show a specific component
// as modal but with some "auto-magic"
export function useSimpleModal(): {
  alert: ModalCreator;
  confirm: ModalCreator;
  failure: ModalCreator;
  custom: (
    component: Component,
    options: ModalCustomOptions
  ) => Promise<ModalChild>;
} {
  return {
    alert: async (content, options) => {
      return await showModal(AlertModal, content, options);
    },
    confirm: async (content, options) => {
      return await showModal(ConfirmModal, content, options);
    },
    failure: async (content, options) => {
      return await showModal(FailureModal, content, options);
    },
    custom: async (component: Component, options: ModalCustomOptions) => {
      const { open, close, patchOptions } = useModal({
        component: BaseModal,
        attrs: {
          onCancel: () => {
            log(LogLevel.Debug, "closing modal by cancel");
            if (options.onClose) options.onClose(false, close);
            if (!options.noAutoClose) close();
          },
          onConfirm: () => {
            log(LogLevel.Debug, "closing modal by confirm");
            if (options.onClose) options.onClose(true, close);
            if (!options.noAutoClose) close();
          },
          hideCancel: options.hideCancel,
          hideConfirm: options.hideConfirm,
          confirmLabel: options.confirmLabel,
        },
        slots: {
          default: {},
        },
      });

      const asModalChild = craftModalChildOptions(close, options);

      patchOptions({
        slots: {
          default: {
            component: component,
            attrs: reactive({
              ...(options.componentProps && isReactive(options.componentProps)
                ? toRefs(options.componentProps)
                : options.componentProps || {}),
              asModalChild,
              ...eventNameConverter(options.componentOn ?? {}),
            }),
          },
        },
      });

      open();

      return asModalChild;
    },
  };
}

function craftModalChildOptions(
  close: () => Promise<string>,
  options: ModalCustomOptions
): ModalChild {
  return {
    doClose: async (confirm: boolean) => {
      log(LogLevel.Debug, "closing modal from inside with confirm", confirm);
      if (options.onClose) options.onClose(confirm, close);
      if (!options.noAutoClose) await close();
    },
  };
}

async function showModal(
  modalClass: typeof AlertModal | typeof ConfirmModal | typeof FailureModal,
  content: string,
  options: ModalOptions
): Promise<ModalResult> {
  const modalConfig = new modalClass(content, options);

  return new Promise((resolve) => {
    const { open, close } = useModal<CustomModalProps>({
      component: SimpleModal,
      attrs: {
        hideCancel: modalConfig.hideCancel,
        hideConfirm: modalConfig.hideConfirm,
        confirmLabel: modalConfig.confirmLabel,
        cancelLabel: modalConfig.cancelLabel,
        title: modalConfig.title,
        content: modalConfig.content,
        onCancel: () => {
          resolve(createModalResult(false));
          close();
        },
        onConfirm: () => {
          resolve(createModalResult(true));
          close();
        },
      },
    });
    open();
  });
}

function createModalResult(confirm: boolean): ModalResult {
  return {
    confirm,
  };
}
