import {
  addDays,
  addHours,
  addMilliseconds,
  differenceInCalendarDays,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  formatISO,
  isAfter,
  isBefore,
  isSameDay,
  isSameSecond,
  isSameWeek,
  set,
  startOfDay,
  startOfWeek,
  startOfHour,
  differenceInMinutes,
  addMinutes,
  endOfHour,
  subMinutes,
  getHours,
  getMinutes,
  getSeconds,
  getMilliseconds,
} from "date-fns";
import { de } from "date-fns/locale";
import { getTimezoneOffset } from "date-fns-tz";

import { log, LogLevel } from "shared/utils/logger";
import { Interval } from "shared/interfaces/interval";
import { range } from "frontend/utils/array";

export function startOfMonth(date: Date): Date {
  return set(startOfDay(date), { date: 1 });
}

export function getFullWeeksOfMonth(date: Date): Array<Array<Date>> {
  const startWeek = startOfWeek(startOfMonth(date), {
    locale: currentLocale(),
  }); // first day of first week
  const endWeek = startOfWeek(endOfMonth(date), { locale: currentLocale() }); // first day of last week

  const result: Array<Array<Date>> = [];

  let currentWeek = startWeek;

  // iterate over weeks (currentWeek is always the first day in the week)
  // until we reach a day that is after the first day of the last week
  while (!isAfter(currentWeek, endWeek)) {
    const week: Array<Date> = []; // save days of current week

    let currentDay = currentWeek;

    // iterate over each day of current week
    while (isSameWeek(currentDay, currentWeek, { locale: currentLocale() })) {
      week.push(currentDay);
      currentDay = addDays(currentDay, 1);
    }

    currentWeek = currentDay;

    // add week to result
    result.push(week);
  }

  return result;
}

export function getShortWeekDayNames(): Array<string> {
  const now = new Date();
  return eachDayOfInterval({
    start: startOfWeek(now, { locale: currentLocale() }),
    end: endOfWeek(now, { locale: currentLocale() }),
  }).map((day) => format(day, "EEEEEE", { locale: currentLocale() }));
}

export function today(): Date {
  return startOfDay(new Date());
}

export function tomorrow(): Date {
  return startOfDay(addDays(new Date(), 1));
}

export function atDefaultStartOf(date: Date): Date {
  return addHours(startOfDay(date), 7);
}

export function atDefaultEndOf(date: Date): Date {
  return addHours(startOfDay(date), 19);
}

export enum DateFormat {
  DateOnlyLong = "EEEEEE, dd.MM.yyyy",
  DateOnly = "dd.MM.yyyy",
  DateOnlyWithoutYear = "dd.MM.",
  DateOnlyWithoutYearWithWeekday = "E, dd.MM.",
  DayOfMonth = "d",
  NameOfMonthWithYear = "LLLL yyyy",
  ISO = "ISO",
  DateOnlyISO = "DateOnlyISO",
  YearAndMonthOnlyISO = "yyyy-MM",
  WeekdayShortOnly = "E",
  TimeOnlyWithoutSeconds = "HH:mm",
  DateAndTime = "dd.MM.yyyy HH:mm",
  CalendarWeek = "'KW' II",
}

export function formatDate(date: Date, style: DateFormat): string {
  if (style === DateFormat.ISO) {
    return formatISO(date);
  } else if (style == DateFormat.DateOnlyISO) {
    return formatISO(date, { representation: "date" });
  } else {
    return format(date, style, { locale: currentLocale() });
  }
}

/**
 * Same as formatDate but with a arbitrary format string
 * @param date
 * @param style
 */
export function formatDateWithString(date: Date, style: string): string {
  return formatDate(date, style as DateFormat);
}

/**
 * javascript dates are always in UTC (internally) and displayed in users local timezone
 * use this to display the date (and time) in utc
 *
 * @param date
 * @param style
 * @param timezone
 */
export function formatDateAsUTC(date: Date, style: string): string {
  return formatDateWithString(shiftFromUTCToLocal(date), style);
}

/**
 * takes a simulated UTC-date (see #shiftFromLocalToUTC) and
 * converts it "back" to local timezone
 *
 * @param date
 */
export function shiftFromLocalToUTC(date: Date): Date {
  return shiftFromToUtc(date, true);
}

/**
 * counterpart to #shiftFromUTCToLocal
 * "simulates" a date in UTC by shifting the original date
 * from the local timezone to UTC
 *
 * @param date
 */
export function shiftFromUTCToLocal(date: Date): Date {
  return shiftFromToUtc(date, false);
}

function shiftFromToUtc(date: Date, forward: boolean): Date {
  return addMilliseconds(date, (forward ? 1 : -1) * offsetToUtcInMs(date));
}

/**
 * checks if the given date (without time-component) is included
 * in the given array
 *
 * For all dates only the date and **not** the time component is
 * considered
 *
 * @export
 * @param {Date} day
 * @param {Date[]} array
 * @returns {boolean}
 */
export function dateIncludedIn(day: Date, array: Array<Date>): boolean {
  return array.some((arrDay) => isSameDay(day, arrDay));
}

/**
 * Takes two dates, extractes the date from one and the time from the other
 * and marges it to a destination Date (return value)
 *
 * @param dateSource
 * @param timeSource
 * @param adjustForUTCOffset - (optional) can be used to ensure UTC-mocking
 * translates correct if the base-date of a date is changed between timezones (summer / winter time)
 */
export function mergeDates(
  dateSource: Date,
  timeSource: Date,
  adjustForUTCOffset?: Date
): Date {
  const newDate = set(dateSource, {
    hours: getHours(timeSource),
    minutes: getMinutes(timeSource),
    seconds: getSeconds(timeSource),
    milliseconds: getMilliseconds(timeSource),
  });

  if (adjustForUTCOffset) {
    return adjustTimeBetweenZones(newDate, adjustForUTCOffset);
  } else {
    return newDate;
  }
}

/**
 * Returns the currently used locale.
 * Right now it will always return "de" as locale,
 * but this might change in the future
 *
 * @returns the currently used locale
 */
export function currentLocale(): Locale {
  return de;
}

/**
 * Returns first and last day of a week
 *
 * @param date the date of a day within the week
 * @returns start: Date and end: Date
 */
export function weekBoundaries(date: Date): { start: Date; end: Date } {
  return {
    start: startOfWeek(date, { locale: currentLocale() }),
    end: endOfWeek(date, { locale: currentLocale() }),
  };
}

/**
 * Compares dates with respect to the day-part only
 * and returns if the dateLeft ist after (or on the same day)
 * as dateRight
 *
 * @param dateLeft
 * @param dateRight
 * @returns boolean
 */
export function dayOnlyIsAfterOrSame(dateLeft: Date, dateRight: Date): boolean {
  return isSameDay(dateLeft, dateRight) || isAfter(dateLeft, dateRight);
}

export function daysBeween(interval: { start: Date; end: Date }): Array<Date> {
  const difference = Math.max(
    0,
    differenceInCalendarDays(interval.end, interval.start)
  );
  return range(0, difference).map((amountOfDays) =>
    addDays(interval.start, amountOfDays)
  );
}

function adjustTimeBetweenZones(newDate: Date, oldDate: Date): Date {
  // in ms
  const offset = offsetToUtcInMs(newDate) - offsetToUtcInMs(oldDate);

  if (offset && offset !== 0) {
    log(
      LogLevel.Debug,
      `adjusting for utc-offset for ${offset}ms / ${offset / 1000 / 3600} h`
    );
    return addMilliseconds(newDate, offset);
  } else {
    return newDate;
  }
}

function offsetToUtcInMs(date: Date): number {
  return getTimezoneOffset(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    date
  );
}

export function sanitizeInterval(interval: Interval): Interval {
  if (isBefore(interval.to, interval.from)) {
    if (isSameSecond(startOfDay(interval.from), interval.to)) {
      return { from: interval.from, to: endOfDay(interval.from) };
    } else {
      log(LogLevel.Warn, "invalid interval without solution!", interval);
      return {
        from: interval.from,
        to: interval.from,
      };
    }
  } else {
    return interval;
  }
}

function floorCeil(
  date: Date,
  precision: number,
  baseMethod: (date: Date) => Date,
  floorCeilMethod: (date: Date, number: number) => Date
): Date {
  const baseDate = baseMethod(date);

  if (!Number.isInteger(precision)) {
    log(LogLevel.Warn, "precision has to be an integer", precision);
    return date;
  } else if (precision <= 0) {
    log(LogLevel.Warn, "precision has to be greater than 0", precision);
    return date;
  } else if (precision > 60) {
    log(
      LogLevel.Warn,
      "precision has to be lower than 60, defaulting to 60",
      precision
    );
    return baseDate;
  } else {
    const difference = Math.abs(differenceInMinutes(date, baseDate)); // between [0, 60)
    return floorCeilMethod(
      baseDate,
      precision * Math.floor(difference / precision)
    );
  }
}

export function floor(dateToFloor: Date, floorPrecision = 30): Date {
  return floorCeil(dateToFloor, floorPrecision, startOfHour, addMinutes);
}

export function ceil(dateToCeil: Date, ceilPrecision = 30): Date {
  return floorCeil(dateToCeil, ceilPrecision, endOfHour, subMinutes);
}

export function isBetween(
  pivot: Date,
  left: Date | null,
  right: Date | null,
  options?: {
    accuracy?: "none" | "day";
  }
): boolean {
  const leftCheck =
    !left ||
    isAfter(pivot, left) ||
    (options?.accuracy === "day" && isSameDay(pivot, left));
  const rightCheck =
    !right ||
    isBefore(pivot, right) ||
    (options?.accuracy === "day" && isSameDay(pivot, right));
  return leftCheck && rightCheck;
}

export function isSameDate(dateLeft: Date, dateRight: Date) {
  return dateLeft.getTime() === dateRight.getTime();
}
