<script lang="ts">
  import { defineComponent } from "vue";

  export default defineComponent({
    props: {
      heading: {
        type: String,
        default: null,
      },
      visualBottomSpacing: {
        type: Boolean,
        default: false,
      },
      visualLight: {
        type: Boolean,
        default: false,
      },
      visualNoHeading: {
        type: Boolean,
        default: false,
      },
    },
  });
</script>

<template>
  <div
    class="vue-component__base-box box"
    v-bind:class="{
      'box--bottom-spacing': visualBottomSpacing,
      'box--light': visualLight,
    }"
  >
    <div
      v-if="!visualNoHeading"
      class="box__heading"
      role="heading"
      aria-level="2"
    >
      <slot name="heading">{{ heading }}</slot>
    </div>
    <div class="box__content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";
  @use "frontend/styles/dimensions";
  @use "frontend/styles/features";

  .box {
    // positioning inside
    padding: 20px;

    // visual style
    background-color: colors.$color_box--background;
    color: colors.$color_box--text;
    font-size: 14px;
    border-radius: dimensions.$dimension_box--border-radius;
  }

  .box__heading {
    // positioning outside
    margin-bottom: 10px;

    // positioning inside
    text-align: center;
    padding-bottom: 15px;
    padding-top: 15px;

    // visual style
    color: colors.$color_box__heading--text;
    @if features.$feature_heading--uppercase {
      text-transform: uppercase;
    }
    font-weight: bold;
    font-size: 19px;
    border-bottom: 1px solid colors.$color_box__heading--separator;
  }

  .box--bottom-spacing {
    margin-bottom: 15px;
  }

  .box--light {
    background-color: colors.$color_box__light--background;
  }
</style>
