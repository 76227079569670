import { Filter } from "frontend/uses/filter/filter";
import {
  BaseS,
  FilterBackendParams,
  FilterDefinition,
} from "frontend/uses/filter/filter-definition";
import { useSubscription } from "frontend/uses/use-subscription";

export type FilterType = {
  isFrontend: boolean;
  isBackend: boolean;
};

// needed for some typescript syntax quirk
const dummySubscription = () => useSubscription<FilterType>();

export class FilterManager<EntityType> {
  private internalFilters: readonly Filter<EntityType, BaseS>[];
  private internalSubscription: ReturnType<typeof dummySubscription>;

  constructor(filterDefinitions: Array<FilterDefinition<EntityType, BaseS>>) {
    this.internalFilters = filterDefinitions.map(
      (definition) => new Filter<EntityType, BaseS>(definition, this)
    );

    this.internalSubscription = useSubscription<FilterType>();
  }

  filters(): readonly Filter<EntityType, BaseS>[] {
    return [...this.internalFilters];
  }

  filter(collection: Array<EntityType>): Array<EntityType> {
    return this.internalFilters.reduce(
      (result, next) => next.filter(result),
      collection
    );
  }

  sort(collection: Array<EntityType>): Array<EntityType> {
    return this.internalFilters.reduce(
      (result, next) => next.sort(result),
      collection
    );
  }

  backendQuery(): FilterBackendParams {
    return this.internalFilters.reduce(
      (result, next) => ({ ...result, ...next.backendQuery() }),
      {}
    );
  }

  registerStateChange(_filter: Filter<EntityType, BaseS>) {
    this.internalSubscription.notify({
      isBackend: _filter.isBackendFilter,
      isFrontend: _filter.isFrontendFilter || _filter.isFrontendSort,
    });
  }

  subscribeStateChange(callback: (argument: FilterType) => void): {
    off: () => void;
  } {
    const handle = this.internalSubscription.subscribe((_handle, argument) => {
      if (!argument) return; // should never happen
      callback(argument);
    });

    return {
      off: () => {
        this.internalSubscription.unsubscribe(handle);
      },
    };
  }
}
