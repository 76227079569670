<script lang="ts">
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    ref,
    unref,
  } from "vue";
  import { useRouter } from "vue-router";
  import { addMonths, compareAsc, isSameDay } from "date-fns";

  import { requestAppointmentTypes } from "frontend/api/application/request-appointment-types";
  import {
    requestSuggestions,
    discardSuggestion,
  } from "frontend/api/application/request-suggestions";
  import { requestPatient } from "frontend/api/application/request-patients";
  import { parseSuggestions } from "frontend/parser/parse-suggestions";
  import { parseAppointmentType } from "frontend/parser/parse-appointment-type";
  import { parsePatientWithAppointments } from "frontend/parser/parse-patient";
  import { AppointmentType } from "frontend/interfaces/appointment-type";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import {
    AppointmentSuggestion,
    Suggestions,
    UnitSuggestion,
  } from "frontend/interfaces/suggestions";
  import { Patient } from "frontend/interfaces/patient";
  import { useDayView } from "frontend/uses/abstract-view/use-day-view";
  import {
    today,
    DateFormat,
    formatDate,
    isSameDate,
  } from "shared/utils/date-utils";
  import { MiniCalendarDay } from "frontend/interfaces/mini-calendar";
  import { CalendarEntry } from "frontend/interfaces/calendar";
  import { WizardStep } from "frontend/interfaces/wizard";
  import { useSimpleModal } from "frontend/uses/simple-modal/use-simple-modal";
  import { FormSelectId } from "frontend/uses/use-form-select";
  import { usePillarCalendar } from "frontend/uses/use-pillar-calendar";
  import { SuggestionsModal } from "frontend/utils/modals/suggestions-modal";

  import WizardSteps from "frontend/components/WizardSteps.vue";
  import PatientSearch from "frontend/components/PatientSearch.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import MiniCalendarAsBox from "frontend/components/MiniCalendarAsBox.vue";
  import BaseCalendar from "frontend/components/base/BaseCalendar.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import ConfirmAppointment from "frontend/components/ConfirmAppointment.vue";
  import FormDatetime from "frontend/components/form/FormDatetime.vue";
  import FormText from "frontend/components/form/FormText.vue";
  import FormSelectOfficePerson from "frontend/components/form/FormSelectOfficePerson.vue";
  import TimeSuggestions from "frontend/components/TimeSuggestions.vue";
  import BaseListItem from "frontend/components/base/BaseListItem.vue";

  export default defineComponent({
    components: {
      WizardSteps,
      PatientSearch,
      BaseButton,
      MiniCalendarAsBox,
      BaseCalendar,
      BaseHelp,
      FormDatetime,
      FormText,
      FormSelectOfficePerson,
      TimeSuggestions,
      BaseListItem,
    },
    props: {
      patient: {
        type: String,
        default: null,
      },
      appointmentType: {
        type: String,
        default: null,
      },
      appointment: {
        type: String,
        default: null,
      },
    },

    setup(props) {
      const steps = ref<WizardStep[]>([
        {
          label: "Patient",
          slotName: "patient",
        },
        {
          label: "Termintyp",
          slotName: "appointment-type",
        },
        {
          label: "erzeugen",
          slotName: "suggestions",
        },
        {
          label: "wählen",
          slotName: "calendar",
        },
      ]);
      const selectStep = ref<WizardStep>(unref(steps)[0]);

      const selectedPatient = ref<Patient | null>(null);
      const appointmentTypes = ref<AppointmentType[]>([]);
      const selectedAppointmentType = ref<PrimaryKey | null>(null);
      const searchFrom = ref<Date>(today());
      const selectedPerson = ref<FormSelectId>(null);
      const suggestions = ref<Suggestions | null>(null);
      const suggestionsList = ref<AppointmentSuggestion[]>([]);
      const selectedSuggestionDate = ref<Date | null>(null);
      const router = useRouter();
      const calendarView = ref<boolean>(true);
      const disableBackwardNavigation = ref<boolean>(false);

      onMounted(async () => {
        if (props.patient) {
          const patientRaw = await requestPatient(props.patient);
          if (!patientRaw) return;
          await doSelectPatient(parsePatientWithAppointments(patientRaw));
        }
        if (props.appointmentType) {
          doSelectAppointmentType(props.appointmentType);
        }
        if (props.appointment) {
          calendarView.value = false;
          disableBackwardNavigation.value = true;
        }
      });

      //Patient
      const doSelectPatient = async (patient: Patient | null) => {
        selectedPatient.value = patient;
        await reloadAppointmentTypes();
        selectStep.value = unref(steps)[1];
      };
      const computedPatientData = computed(() => {
        if (selectedPatient.value) {
          return `${selectedPatient.value.first_name} ${
            selectedPatient.value.name
          }, ${formatDate(
            selectedPatient.value.date_of_birth,
            DateFormat.DateOnly
          )} (${selectedPatient.value.human_age})`;
        }
        return null;
      });

      //AppointmentType
      const doSelectAppointmentType = (
        appointmentTypeId: string | null
      ): void => {
        if (appointmentTypeId) {
          selectedAppointmentType.value = appointmentTypeId;
          selectStep.value = unref(steps)[2];
        } else selectedAppointmentType.value = null;
      };
      const reloadAppointmentTypes = async () => {
        const patient = unref(selectedPatient);
        if (!patient) return;
        const data = await requestAppointmentTypes(patient.id);
        // fix prettier linting
        const parsedValues = data.appointment_types.map(parseAppointmentType);
        appointmentTypes.value = parsedValues;
      };

      const isAppointmentTypeRegular = (ap: AppointmentType) =>
        ap.matching_insurance &&
        ap.on_schedule &&
        !ap.max_booking_count_reached;
      const appointmentTypesRegular = computed(() => {
        return (appointmentTypes.value || []).filter(isAppointmentTypeRegular);
      });
      const appointmentTypesIrregular = computed(() => {
        return (appointmentTypes.value || []).filter(
          (ap) => !isAppointmentTypeRegular(ap)
        );
      });

      //suggestions
      const suggestionReset = () => {
        const searchRef = unref(suggestions)?.search_ref;
        if (searchRef) discardSuggestion(searchRef); // do not await, just fire
        selectedSuggestionDate.value = null;
        suggestions.value = null;
        selectStep.value = unref(steps)[2];
      };
      const suggestionsLoading = ref(false);
      const reloadSuggestions = async () => {
        suggestionsLoading.value = true;
        try {
          suggestionReset();
          const patient = unref(selectedPatient);
          const appointmentTypeID = unref(selectedAppointmentType);
          if (!patient || !appointmentTypeID) return;
          const data = await requestSuggestions(
            patient.id,
            appointmentTypeID,
            searchFrom.value,
            selectedPerson.value as string,
            props.appointment
          );
          if (!data.suggestions) {
            suggestions.value = null;
          } else {
            suggestions.value = parseSuggestions(data.suggestions);
            suggestionsList.value = suggestions.value.suggestion_list.sort(
              (a, b) => compareAsc(a.begin_for_patient, b.begin_for_patient)
            );
            selectStep.value = unref(steps)[3];
            miniCalendarMonth.value = searchFrom.value;
            selectedSuggestionDate.value = searchFrom.value;
          }
        } finally {
          suggestionsLoading.value = false;
        }
      };
      const selectSuggestionDate = (selectedDate: MiniCalendarDay) => {
        selectedSuggestionDate.value = selectedDate.date;
      };
      const suggestionsSearchRef = computed((): PrimaryKey | null => {
        return (unref(suggestions) || {}).search_ref || null;
      });

      // day-view
      const miniCalendarMonth = ref<Date>(searchFrom.value);
      const miniCalendarMonthLabel = computed(() => {
        return formatDate(
          unref(miniCalendarMonth),
          DateFormat.NameOfMonthWithYear
        );
      });
      const miniCalendarSelectedDays = computed((): Array<Date> => {
        const dateUnref = unref(selectedSuggestionDate);
        if (!dateUnref) return [];
        return [dateUnref];
      });
      const {
        calendar,
        date: displayedDate,
        triggerReload: doTriggerReload,
      } = useDayView(selectedSuggestionDate, {
        searchRef: suggestionsSearchRef,
      });

      const doMiniCalendarNextMonth = (up: boolean) => {
        const amount = up ? 1 : -1;
        miniCalendarMonth.value = addMonths(unref(miniCalendarMonth), amount);
      };
      const theAppointmentType = computed(() => {
        return unref(appointmentTypes).filter(
          (ap) => unref(selectedAppointmentType) === ap.id
        )[0];
      });

      const initialHighlight = ref<string>("");
      const { pillarCalendar } = usePillarCalendar<AppointmentSuggestion>(
        suggestionsList,
        (suggestion) => ({
          id: suggestion.appointment_id,
          time: suggestion.begin,
        }),
        async (suggestion) => {
          selectedSuggestionDate.value = suggestion.begin;
          miniCalendarMonth.value = suggestion.begin;
          initialHighlight.value = suggestion.appointment_id;
        }
      );
      const selectedPillar = computed(() => {
        const date = selectedSuggestionDate.value;
        if (date) {
          return (
            pillarCalendar.value.pillars.find((pillar) =>
              isSameDay(pillar.day, date)
            ) ?? null
          );
        }
        return null;
      });

      const doChangeStep = (wizardStep: WizardStep | null) => {
        if (wizardStep) selectStep.value = wizardStep;
      };

      const mainSwitchButtons = [
        {
          key: "1",
          label: "Kalender",
          onSubmit: () => {
            calendarView.value = true;
          },
          isActive: computed(() => calendarView.value),
        },
        {
          key: "2",
          label: "Liste",
          onSubmit: () => {
            calendarView.value = false;
            initialHighlight.value = "";
          },
          isActive: computed(() => !calendarView.value),
        },
      ];

      //suggestion select
      const onSelectList = (suggestion: AppointmentSuggestion) => {
        props.appointment
          ? confirmMoveAppointment(suggestion)
          : confirmAppointment(suggestion.units[0].id);
      };

      const onSelectEntry = (entry: CalendarEntry) => {
        if (props.appointment) {
          const suggestion = suggestionsList.value.find((s) =>
            s.units.some((u) => u.id === entry.key)
          );
          if (!suggestion) return;
          confirmMoveAppointment(suggestion);
        } else {
          if (!displayedDate.value) return;
          if (
            entry &&
            (entry as unknown as { disabled: undefined | boolean }).disabled
          )
            return;
          confirmAppointment(entry.key);
        }
      };

      const confirmMoveAppointment = async (
        suggestion: AppointmentSuggestion
      ) => {
        new SuggestionsModal()
          .setData(
            reactive({
              appointmentId: props.appointment,
              unitId: suggestion.units[0].id,
              begin: suggestion.begin,
              beginForPatient: suggestion.begin_for_patient,
              notifyPatient: true,
              reason: "",
              isLoading: false,
            })
          )
          .onSuccess(() =>
            router.push({
              name: "calendar-day",
              params: {
                day: formatDate(suggestion.begin, DateFormat.DateOnlyISO),
              },
            })
          )
          .show();
      };

      const { custom } = useSimpleModal();
      const confirmAppointment = async (unitId: PrimaryKey) => {
        await custom(ConfirmAppointment, {
          hideCancel: true,
          hideConfirm: true,
          componentProps: reactive({
            unitId: unitId,
          }),
        });
      };

      const isFirstDay = (suggestion: AppointmentSuggestion) => {
        const position = suggestionsList.value.indexOf(suggestion);
        if (position === 0) return true;
        return !isSameDay(
          suggestionsList.value[position - 1].begin,
          suggestion.begin
        );
      };

      const colorStyle = (unit: UnitSuggestion) => {
        return {
          borderLeft: `7px solid ${unit.color ?? "#000000"}`,
          marginRight: "5px",
        };
      };

      return {
        appointmentTypesRegular,
        appointmentTypesIrregular,
        selectedAppointmentType,
        reloadAppointmentTypes,

        suggestions,
        selectedSuggestionDate,
        suggestionsLoading,
        reloadSuggestions,
        selectSuggestionDate,
        suggestionReset,

        miniCalendarMonth,
        miniCalendarMonthLabel,
        miniCalendarSelectedDays,

        calendar,
        doMiniCalendarNextMonth,
        doTriggerReload,
        theAppointmentType,

        DateFormat,
        formatDate,

        steps,
        selectedPatient,
        computedPatientData,
        searchFrom,
        selectedPerson,
        selectStep,
        doChangeStep,
        doSelectPatient,
        doSelectAppointmentType,

        initialHighlight,
        pillarCalendar,
        selectedPillar,

        mainSwitchButtons,
        calendarView,
        disableBackwardNavigation,
        suggestionsList,

        colorStyle,
        onSelectList,
        onSelectEntry,
        isFirstDay,
        isSameDate,
      };
    },
  });
</script>

<template>
  <div class="vue-route__appointment--new plain">
    <h2>{{ appointment ? "Termin verschieben" : "Neuen Termin anlegen" }}</h2>
    <WizardSteps
      v-bind:steps="steps"
      v-bind:select-step="selectStep"
      disable-forward-navigation
      v-bind:disable-backward-navigation="disableBackwardNavigation"
      v-on:step-selected="doChangeStep"
    >
      <template v-slot:patient>
        <h3>Patienten auswählen</h3>
        <p>
          Geben Sie den Namen, die Versichertennummer oder die Krankenkasse
          eines Patienten ein, um in der Datenbank nach Patienten zu suchen:
        </p>
        <PatientSearch v-on:patient-selected="doSelectPatient" />
      </template>
      <template v-slot:appointment-type>
        <h3>Termintyp auswählen</h3>
        <p>
          Basierend auf Ihrer Patientenauswahl, wählen Sie nun den Termintyp
          aus, den Sie anlegen möchten
        </p>
        <div class="aptype__container">
          <div
            v-for="apType in appointmentTypesRegular"
            v-bind:key="apType.id"
            class="aptype"
            v-bind:class="{
              'aptype--selected': selectedAppointmentType === apType.id,
            }"
            v-on:click="doSelectAppointmentType(apType.id)"
          >
            <strong>{{ apType.name }}</strong>
            <div class="aptype__info">{{ apType.notes }}</div>
          </div>
        </div>

        <h5>Unreguläre Termintypen</h5>
        <BaseHelp>
          Hier werden alle Termintypen aufgelistet, die eigentlich für den
          ausgewählten Patienten nicht in Frage kommen. Sie können aber
          natürlich trotzdem Termine dafür anlegen.
        </BaseHelp>
        <div class="aptype__container">
          <div
            v-for="apType in appointmentTypesIrregular"
            v-bind:key="apType.id"
            class="aptype"
            v-bind:class="{
              'aptype--selected': selectedAppointmentType === apType.id,
            }"
            v-on:click="doSelectAppointmentType(apType.id)"
          >
            <strong>{{ apType.name }}</strong>
            <div class="aptype__info">{{ apType.notes }}</div>
            <div
              v-if="!apType.on_schedule"
              class="aptype__info aptype__info--irregularity"
            >
              Patientenalter nicht passend oder maximale Buchungen in diesem
              Zeitraum erreicht
            </div>
            <div
              v-if="!apType.matching_insurance"
              class="aptype__info aptype__info--irregularity"
            >
              Versicherung nicht passend
            </div>
            <div
              v-if="apType.max_booking_count_reached"
              class="aptype__info aptype__info--irregularity"
            >
              Maximale Anzahl an Buchungen für diesen Termintyp erreicht
            </div>
          </div>
        </div>
      </template>
      <template v-slot:suggestions>
        <h3>Terminvorschläge erzeugen</h3>
        <p>
          Mit einem Klick erzeugen Sie im System automatisch Vorschläge für
          mögliche Termine. Terminvorschläge werden für folgende Auswahl
          erzeugt:
        </p>
        <FormText class="termin-info" label="Patient">
          {{ computedPatientData }}
        </FormText>
        <FormText class="termin-info" label="Termintyp">
          {{ theAppointmentType.name }}
        </FormText>
        <FormDatetime
          v-model="searchFrom"
          label="Termin suchen ab"
          visual-small
          v-bind:allow-deletion="false"
        />
        <FormSelectOfficePerson
          v-model="selectedPerson"
          label="Mitarbeiter"
          visual-small
          include-blank="-- Keine Präferenz --"
        />
        <p>
          <BaseButton
            v-bind:loading="suggestionsLoading"
            v-on:submit="reloadSuggestions()"
          >
            Terminvorschläge anfordern
          </BaseButton>
        </p>
      </template>

      <template v-slot:calendar>
        <p>
          Ihre Terminvorschläge für
          <strong>
            {{ computedPatientData }}
          </strong>
          für den Termintyp <strong>{{ theAppointmentType.name }}</strong>
          <span class="back-to-settings" v-on:click="suggestionReset">
            Auswahl anpassen
          </span>
        </p>

        <template v-if="suggestions && suggestions.found_on_days.length > 0">
          <div class="topbar__attendance-view">
            <BaseButton
              v-for="button in mainSwitchButtons"
              v-bind:key="button.key"
              v-bind:class="{ 'button--inverted': !button.isActive.value }"
              v-on:submit="button.onSubmit()"
            >
              {{ button.label }}
            </BaseButton>
          </div>

          <div v-if="calendarView" class="calendars__container">
            <div class="calendars__mini">
              <MiniCalendarAsBox
                v-model="miniCalendarMonth"
                v-bind:highlighted-days="suggestions.found_on_days"
                v-bind:selected-days="miniCalendarSelectedDays"
                v-on:date-selected="selectSuggestionDate"
              />
              <TimeSuggestions
                v-bind:pillar-calendar="pillarCalendar"
                v-bind:selected-pillar="selectedPillar"
              />
            </div>
            <div class="calendars__big">
              <template v-if="selectedSuggestionDate">
                <BaseCalendar
                  v-if="calendar"
                  v-bind:calendar="calendar"
                  v-bind:initial-highlight="initialHighlight"
                  enable-drag-and-drop
                  note-sticky
                  v-on:entry="onSelectEntry"
                  v-on:refresh-needed="doTriggerReload"
                />
              </template>
              <p v-else>
                Wählen Sie links ein Datum aus, um die Terminvorschläge zu
                sehen. Tage mit Terminvorschlag sind auf der linken Seite
                farblich hervorgehoben.
              </p>
            </div>
          </div>

          <div v-if="!calendarView">
            <div>
              <div
                v-for="suggestion in suggestionsList"
                v-bind:key="suggestion.appointment_id"
              >
                <div
                  v-if="isFirstDay(suggestion)"
                  class="appointment-suggestion__date"
                >
                  {{ formatDate(suggestion.begin, DateFormat.DateOnlyLong) }}
                </div>
                <BaseListItem
                  visual-small
                  v-on:select="onSelectList(suggestion)"
                >
                  <template v-slot:description>
                    <span
                      v-if="
                        !isSameDate(
                          suggestion.begin,
                          suggestion.begin_for_patient
                        )
                      "
                      class="appointment-suggestion__order-time"
                    >
                      <span class="appointment-suggestion__time">{{
                        formatDate(
                          suggestion.begin_for_patient,
                          DateFormat.TimeOnlyWithoutSeconds
                        )
                      }}</span>
                      Einbestellzeit
                    </span>
                    <span
                      v-for="unit in suggestion.units"
                      v-bind:key="unit.id"
                      class="appointment-suggestion__unit"
                    >
                      <span v-bind:style="colorStyle(unit)"></span>
                      <span class="appointment-suggestion__time">{{
                        formatDate(unit.from, DateFormat.TimeOnlyWithoutSeconds)
                      }}</span>
                      {{ unit.participations[0] }}</span
                    >
                  </template>
                </BaseListItem>
              </div>
            </div>
          </div>
        </template>
        <p v-else>Es konnten keine Terminvorschläge gefunden werden.</p>
      </template>
    </WizardSteps>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .aptype__container {
    display: flex;
    flex-wrap: wrap;
  }

  .aptype {
    padding: 20px 30px;
    border: 1px solid colors.$color_create-appointment__box--border;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: colors.$color_create-appointment__box--background;
    width: 100%;

    cursor: pointer;

    &:hover,
    &.aptype--selected {
      background-color: colors.$color_create-appointment__box-hover-selection--background;
      color: colors.$color_create-appointment__box-hover-selection--text;
    }
  }
  .aptype__info {
    font-size: 14px;
  }

  .aptype__info--irregularity {
    color: colors.$color_create_appointment__box__warning--text;
  }

  .calendars__container {
    display: flex;
  }

  .calendars__mini {
    margin-right: 20px;
  }

  .calendars__big {
    flex: 1 0;
  }

  .back-to-settings {
    cursor: pointer;
    font-size: 12px;
    margin-left: 20px;
    font-style: italic;
  }
  .termin-info {
    margin: 5px 0;
  }

  .topbar__attendance-view {
    text-align: right;
    margin: 20px 0;
  }

  .appointment-suggestion__date {
    font-size: 14px;
    margin-top: 30px;
    font-weight: bold;
  }
  .appointment-suggestion__time {
    display: inline;
    color: colors.$color_header--text;
    font-weight: bold;
  }

  .appointment-suggestion__unit,
  .appointment-suggestion__order-time {
    margin-right: 8px;
  }
</style>
