import { v4 as uuid } from "uuid";

import { HttpPayload } from "frontend/interfaces/http";
import {
  Operator,
  OperatorRaw,
  OperatorNew,
} from "frontend/interfaces/settings/operator";

export function parseOperator(operator: OperatorRaw): Operator {
  return {
    ...operator,
  };
}

// eslint-disable-next-line sonarjs/no-identical-functions -- the will not stay identical forever
export function httpPayloadOperator(operator: Operator): HttpPayload {
  return {
    ...operator,
  };
}

export function newOperator(): OperatorNew {
  return {
    id: `MOCK-${uuid()}`,
    abbreviation: "",
    name: null,

    settings_profile_id: null,

    unsaved: true,
  };
}
